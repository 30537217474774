
  import { Component, Prop, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import LzTable from "@/components/Table.vue";
  import LzConfirm from "@/components/Confirm.vue";
  import { namespace } from "vuex-class";
  import { apiPartners } from "../api";

  const auth = namespace("auth");

  @Component({ components: { LzButton, LzTable, LzConfirm } })
  export default class ShopCreate extends Vue {
    productId: string | null = null;
    showDeleteModal = false;

    partnerForm = {
      name: "",
      birthDay: "",
      surname: "",
      mail: "",
      dni: "",
      phone: "",
      address: "",
      status: "",
      date: "",
      amount: 0,
      comunications: ""
    };

    statusOptions = {
      active: this.$t("partners.detail.form.status.options.active"),
      inactive: this.$t("partners.detail.form.status.options.inactive")
    };

    @auth.State("id")
    public ongId!: string;

    @Prop({ required: true })
    protected subscriptionId!: string;

    mounted() {
      if (this.subscriptionId) {
        apiPartners.getPartner(this.subscriptionId).then(({ data }) => {
          this.partnerForm.name = data.User.firstName;
          this.partnerForm.birthDay = data.User.birthDay;
          this.partnerForm.surname = data.User.lastName;
          this.partnerForm.mail = data.User.email;
          this.partnerForm.dni = data.User.nif;
          this.partnerForm.phone = data.User.mobilePhone;
          this.partnerForm.address = data.User.home_address;
          this.partnerForm.date = data.createdAt;
          this.partnerForm.amount = data.amount;
          this.partnerForm.comunications = data.comunications
            ? this.$t("partners.detail.yes").toString()
            : this.$t("partners.detail.no").toString();
          this.partnerForm.status = data.status
            ? data.status
            : this.$tc("partners.detail.form.status.options.active");
        });
      }
    }

    confirmDeletePartner() {
      this.showDeleteModal = true;
    }

    deletePartner() {
      apiPartners
        .deletePartner(this.ongId, this.subscriptionId)
        .then(() => {
          this.$notify({
            type: "success",
            text: this.$tc("partners.detail.notifications.projectRemoved")
          });
          this.$router.push({ name: "partnersRead" });
        })
        .catch(() => {
          this.$notify({
            type: "error",
            text: this.$tc("common.error.generic")
          });
          this.showDeleteModal = false;
        });
    }

    onCancel() {
      this.$router.push({ name: "partnersRead" });
    }
  }
